/*
  Organization related functions
  - getOrganization() - Get organization of the active user
  - postOrganization() - Generate new organization for active user
  - deleteOrganization() - Delete organization of active user
  - changeOrgName() - Write odkForm to  bend's odk text fields
  - updateTesterState() - Switch on / off experimental features for organization (field_test)
*/
import { useSubscriptionStore } from '@/stores/SubscriptionStore'
const subscription = useSubscriptionStore()

export default {
  methods: {
    /**
     * Get organization of the active user.
     * RETURNS TRUE or FALSE
     *  Logic:
     * 1. Get Organization using api/organization (drupal views)
     *  1.2 Commit organization's data into vuex (bend/setOrganization)
     *  1.3 Set call.organization state to true
     * 2. Get Default Role using api/role (drupal views)
     *  2.2 Commit default Role into organization object in vuex (bend/addToOrganization)
     */
    async getOrganization () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      // console.log('getOrganization da')
      // TO CHECK maybe I don't need this
      // STEP 2 get Default Role
      const defaultRole = getRole => {
        // console.log('getDefaultRole')
        // Get default role
        return new Promise((resolve, reject) => {
          this.$httpBend
            .get('api/role', {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              // if (Array.isArray(response.data)) {
              if (response.data.length > 0) {
                const defaultRole = []
                for (var i = 0, len = response.data.length; i < len; i++) {
                  const obj = {}
                  obj.id = response.data[i].uuid[0].value
                  defaultRole.push(obj)
                }
                // console.log(defaultRole[0].id)
                // STEP 2.2 Commit default Role into organization
                this.$store.commit('bend/addToOrganization', { defaultRole: defaultRole[0].id })
                return resolve(true)
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      // STEP 1. Get Organization
      // console.log('get-organization')
      // {server}/api/organization
      return new Promise((resolve, reject) => {
        this.$httpBend
          .get('api/organization', {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(async response => {
            // console.log(response)

            // if there is an organization, respose data length is more than 0
            if (response.data.length > 0) {
              const organization = []
              for (var i = 0, len = response.data.length; i < len; i++) {
                const obj = {}
                obj.id = response.data[i].uuid[0].value
                obj.nid = response.data[i].nid[0].value
                obj.title = response.data[i].title[0].value
                obj.body = response.data[i].body[0].value
                obj.owneruid = response.data[i].uid[0].target_uuid
                // obj.odk_server = response.data[i].field_url[0].uri
                obj.orgid = response.data[i].field_folderid[0].value
                obj.tester = response.data[i].field_test[0].value
                if (response.data[i].field_image.length > 0) {
                  obj.image = response.data[i].field_image[0].url
                } else {
                  obj.image = null
                }
                // also add empty keys
                obj.currentStaff = []
                obj.allStaff = []
                obj.projects = []
                organization.push(obj)
              }
              // console.log(organization)

              // One Client One organization commit only first one (it is mainly the only organization.)
              // STEP 1.2 Commit new organization's data into vuex (bend/setOrganization)
              // console.log(organization)
              this.$store.commit('bend/setOrganization', organization[0])

              // STEP 1.3 Set call.organization state to true
              this.$store.commit('bend/setCalls', { organization: true })
              // console.log(response)

              // STEP 1.4 Commit new organization's orgid into store.MAIN
              this.$store.commit('main/setOrgid', organization[0].orgid)

              // this.$store.commit('bend/setCalls', { projects: false })
              // console.log(response)
              // If there is no organization below will be false
              await defaultRole()
              // console.log(this.$store.state.bend.organization.nid)
              return resolve(organization.length > 0)
            }
            // send false if no organization
            // console.log(response)
            return resolve(false)
          })
          .catch(() => {
            return resolve(false)
          })
      })
    },

    // Deleteme
    // async postOrg () {
    //   // console.log('ppost org')
    //   await this.setSubscription(this.selectedSubscription)
    // },

    /**
     * Generate new organization for active user.
     * RETURNS True, if successful, or False
     * Logic:
     * One Client - One organization.
     * 1. Post new Organization
     *  1.2 Commit new organization's data into vuex (bend/setOrganization)
     *  1.3 Set call.organization state to true
     *  1.4 Commit new organization's orgid into store.main
     * 2. Post default Role using active user and Organization's id
     *  2.2 Commit defauld Role into vuex (bend/addToOrganization)
     * 3. Patch Current User in the bend to add organization into it
     *  3.1 When current user is updated we need to login again
     */
    async postOrganization () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      // const ocpuPack = this.$store.state.ocpu.ocpuPack
      // console.log('in Post organization')

      // STEP 2. Post default Role using active user and Organization's id
      const defaultRole = postRole => {
        // console.log('defaultRole')
        // We assign active user as a manager and generate a role
        const organizationId = this.$store.state.bend.organization.id
        const uuid = this.$store.state.auth.user.uuid
        // Prepare data to be POST
        const data = {
          data: {
            type: 'node--roles',
            attributes: { field_project_role: 'manager' },
            relationships: {
              field_organization: { data: [{ type: 'node--organization', id: `${organizationId}` }] },
              field_user: { data: [{ type: 'user--user', id: `${uuid}` }] },
            },
          },
        }
        // STEP 2. Post default Role using active user and Organization's id
        return new Promise((resolve, reject) => {
          // console.log(data)
          this.$httpBend
            .post('jsonapi/node/roles', data, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                const id = response.data.data.id

                // STEP 2.2 Commit defauld Role into vuex (bend/addToOrganization)
                this.$store.commit('bend/addToOrganization', { defaultRole: id })
                // console.log(id)
                return resolve(true)
              } else {
                return resolve(false)
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      // STEP 3 Patch Current User to add organization into it
      const updateUsersOrg = (orgid) => {
        // console.log(orgid)
        const body = {
          data: { type: 'node--organization', id: orgid },
        }

        // Patch User and change user type
        return new Promise((resolve, reject) => {
          this.$httpBend
            .patch(`jsonapi/user/user/${uuid}/relationships/field_organization`, body, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              if (response.status === 204) {
                // close 'New Staff' dialogue
                // this.dialogEditUser = false
                // // show information about success
                // this.snackBar = {
                //   type: 'success',
                //   mode: 'multi-line',
                //   title: `${this.$t('common.success')}`,
                //   text: `User ${this.username}'s type is now ${this.selectedType.type}`,
                //   visible: true,
                // }
                resolve(true)
              } else {
                // return resolve(false)
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      // // STEP 4. Run ocfolderorg() function in opencpu to generate an organization folder in OCPU Server
      // const addorgfolder = (organization) => {
      //   const curStaff = this.$store.state.bend.organization.currentStaff[0]
      //   // console.log(curStaff)
      //   const param = {
      //     orgid: organization.orgid,
      //     title: organization.title,
      //     body: organization.body,
      //     ownermail: curStaff.email,
      //     ownername: curStaff.name,
      //   }
      //   return new Promise((resolve, reject) => {
      //     this.$httpOcpu
      //       .post(`/${ocpuPack}/R/ocfolderorg/json`, param)
      //       .then(response => {
      //         // console.log(response)
      //         if (response.status === 201) {
      //           resolve(response)
      //         }
      //       })
      //       .catch(error => {
      //         // console.log(error)
      //         reject(error)
      //       })
      //   })
      // }

      // STEP 1. Post new Organization
      // We add active user to the organization, field_staff
      const uuid = this.$store.state.auth.user.uuid
      // const odkServer = this.$store.state.auth.user.odk_server
      // generate Organization ID using current date and time in milliseconds
      const uniq = 'ORG' + (new Date()).getTime()
      const data = {
        data: {
          type: 'node--organization',
          attributes: {
            title: `${this.orgName}`,
            body: `${this.orgDescription}`,
            // field_url: {
            //   uri: `${odkServer}`,
            // },
            field_folderid: `${uniq}`,
          },
          relationships: {
            field_staff: {
              data: [
                {
                  type: 'user--user',
                  id: `${uuid}`,
                },
              ],
            },
          },
        },
      }
      // {server}/jsonapi/node/organization/4115b34c-853b-4a4a-9164-15bd182c1426/relationships/field_staff
      return new Promise((resolve, reject) => {
        this.$httpBend
          .post('jsonapi/node/organization', data, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          })
          .then(async response => {
            // console.log(response)
            if (response.status === 201) {
              const data = response.data.data
              const organization = {}
              organization.id = data.id
              organization.nid = data.attributes.drupal_internal__nid
              organization.title = data.attributes.title
              organization.body = data.attributes.body.value
              // owner is the one who generated this node
              organization.owneruid = data.relationships.uid.data.id
              // organization.odk_server = data.attributes.field_url.uri
              organization.staff = data.relationships.field_staff
              organization.orgid = uniq
              organization.tester = data.attributes.field_test
              organization.image = data.relationships.field_image.data
              // also add empty keys
              organization.currentStaff = []
              organization.allStaff = []
              organization.roles = []
              organization.projects = []

              // STEP 1.2 Commit new organization's data into vuex (bend/setOrganization)
              // console.log(organization)
              this.$store.commit('bend/setOrganization', organization)

              // STEP 1.3 Set call.organization state to true
              this.$store.commit('bend/setCalls', { organization: true })
              // console.log(response)

              // STEP 1.4 Commit new organization's orgid into store.main
              this.$store.commit('main/setOrgid', uniq)

              // STEP 2. Post default Role using active user and Organization's id
              await defaultRole()

              // Call STEP 3
              await updateUsersOrg(organization.id)

              // STEP 3.1 When current user is updated we need to login again
              await this.$store.dispatch('auth/login')

              // // Call STEP 4
              // await addorgfolder(organization)

              // console.log('final in post organization')
              return resolve(true)
            } else {
              return resolve(false)
            }
            // console.log(response)
          })
          .catch(error => {
            // console.log(error)
            return reject(error.message)
          })
      })
    },

    // Run ocfolderorg() function in opencpu to generate an organization folder in OCPU Server
    async genOrgFolder () {
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      const org = this.$store.state.bend.organization
      // const curStaff = this.$store.state.bend.organization.currentStaff[0]
      // console.log(curStaff)
      const param = {
        orgid: org.orgid,
        title: org.title,
        body: org.body,
        email: org.currentStaff[0].email,
        name: org.currentStaff[0].name,
      }
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/ocfolderorg/json`, param)
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
    },

    // Setting Subscription plan of the organization
    async setSubscription (plan) {
      const main = this.$store.state.main
      // console.log(plan)
      const ocpuPack = this.$store.state.ocpu.ocpuPack

      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/setcapiplan/json`, { orgid: main.orgid, plan: plan })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
      // this.$router.push({ name: this.path })
    },

    // Get list of subscriptions of the organization
    async getSubscription () {
      const main = this.$store.state.main
      const ocpuPack = this.$store.state.ocpu.ocpuPack

      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/getcapiplan/json`, { orgid: main.orgid })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              subscription.$patch((state) => {
                state.mySubscription = response.data
                state.calls.orgsubsc = true
              })
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
      // this.$router.push({ name: this.path })
    },

    /**
     * Delete organization of active user.
     * 1. Patch Current User to delete organization from it
     *  1.2 When current user is updated we need to login again
     * 2. Delete default Role using active user and Organization's id
     *  2.2 Delete defaultRole property from organization
     * 3. Delete Organization Node from bend
     *  3.2. Delete settings in vuex for organization
     *  3.3. Set call.organization state to false
     *  3.4 Delete orgid from store.MAIN
     * 4. Run ocdelfolderorg() function in opencpu to delete the organization's folder in OCPU Server
     * 5. Navigate to Active Forms Page
     */
    async deleteOrganization () {
      this.loading = true
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      // console.log('in Delete organization')
      const org = this.$store.state.bend.organization
      const orgid = org.orgid

      // STEP 1 Patch Current User to delete organization from it
      const updateUsersOrg = () => {
        const body = {
          data: null,
        }
        const uuid = this.$store.state.auth.user.uuid
        return new Promise((resolve, reject) => {
          this.$httpBend
            .patch(`jsonapi/user/user/${uuid}/relationships/field_organization`, body, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              if (response.status === 204) {
                resolve(true)
              } else {
                // return resolve(false)
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      }

      // STEP 2. Delete default Role using active user and Organization's id
      const defaultRole = () => {
        const rolejsonid = org.defaultRole
        // console.log(rolejsonid)

        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/nodedelete/json`, {
              path: `/jsonapi/node/roles/${rolejsonid}`,
              orgid: orgid,
            })
            .then(response => {
              // console.log(response)
              // STEP 2.2 Delete defaultRole property from organization
              this.$store.commit('bend/delOrganization', ['defaultRole'])
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }

      // STEP 3. Delete Organization Node from bend
      const deleteorgnode = () => {
        const projectjsonid = org.id
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/nodedelete/json`, {
              path: `/jsonapi/node/project/${projectjsonid}`,
              orgid: orgid,
            })
            .then(response => {
              // console.log(response)

              // STEP 3.2. Delete settings in vuex for organization
              this.$store.commit('bend/delOrganization', ['id', 'nid', 'title', 'body', 'owneruid', 'orgid', 'tester'])
              // STEP 3.3. Set call.organization state to false
              this.$store.commit('bend/setCalls', { organization: false })
              // STEP 3.4 Delete orgid from store.MAIN
              this.$store.commit('main/delOrgid')
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }

      // STEP 4. Run ocdelfolderorg() function in opencpu to delete the organization's folder in OCPU Server
      const deleteorgfolder = () => {
        const param = { orgid: orgid }
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocdelfolderorg/json`, param)
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      }

      // Call STEP 1
      await updateUsersOrg()

      // STEP 1.2 When current user is updated we need to login again
      await this.$store.dispatch('auth/login')

      // Call STEP 2
      await defaultRole()

      // Call STEP 3
      await deleteorgnode()

      // Call STEP 4
      await deleteorgfolder()

      // back loading to false
      this.loading = false

      // STEP 5. Navigate to Active Forms Page
      this.$router.push({ name: 'Active Forms' })
    },

    // Write odkForm to  bend's odk text fields
    changeOrgName () {
      const org = this.$store.state.bend.organization
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      // console.log(JSON.stringify(state.organization.projects[props.ix.pindex].forms[props.ix.findex].odksettings))
      // console.log(props.formjsonid)

      this.loadingB = true
      // STEP 2. Post new ODK Form
      const patchBody = {
        data: {
          type: 'node--organization',
          id: org.id,
          attributes: {
            title: this.newOrgName,
          },
        },
      }
      // Run OCPU Code to patch node
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/nodepatch/json`, {
            path: `/jsonapi/node/organization/${org.id}`,
            patchBody: patchBody,
            orgid: org.orgid,
          })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              this.$store.commit('bend/addToOrganization', { title: this.newOrgName })
              // back loading to false
              this.loadingB = false
              this.dialogEditOrg = false
              return resolve(true)
            } else {
              return resolve(false)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
            // return reject(error.message)
          })
      })
    },

    // Switch on / off experimental features for organization (field_test)
    async updateTesterState () {
      // Get valid token
      await this.$store.dispatch('auth/getToken')
      const isTester = this.experimental
      const id = this.$store.state.bend.organization.id
      // Prepare body to patch
      const patchBody = {
        data: {
          type: 'node--organization',
          id: id,
          attributes: { field_test: isTester },
        },
      }
      // STEP 1. Patch Organization and add projects only owners can do it
      await this.$httpBend
        .patch(`jsonapi/node/organization/${id}`, patchBody, {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
        })
        .then(
        response => {
          // console.log(response)
          if (response.status === 200) {
            // STEP 2 Commit tester state in vuex
            this.$store.commit('bend/updateTesterState', isTester)
            // console.log(id)
            return true
          }
        })
    },
  },
}
